// import Container from "../Common/Container";

// const Gallery = () => {
//   return (
//     <section>
//       <Container>
//         <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
//           <div className="grid gap-4">
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1708312604124-0a5ee3381273?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center "
//                 src="https://images.unsplash.com/photo-1629367494173-c78a56567877?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1722003184314-3fc893853fff?q=80&w=2025&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//           </div>
//           <div className="grid gap-4">
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://plus.unsplash.com/premium_photo-1664304753883-923c28de6b85?q=80&w=1936&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1708312604109-16c0be9326cd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center "
//                 src="https://docs.material-tailwind.com/img/team-3.jpg"
//                 alt="gallery-photo"
//               />
//             </div>
//           </div>
//           <div className="grid gap-4">
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1599474924187-334a4ae5bd3c?q=80&w=1983&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center "
//                 src="https://docs.material-tailwind.com/img/team-3.jpg"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1626225015999-2e53f6aaa008?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//           </div>
//           <div className="grid gap-4">
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1722003184051-152176274187?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//             <div>
//               <img
//                 className="h-auto max-w-full rounded-lg object-cover object-center"
//                 src="https://images.unsplash.com/photo-1629367494173-c78a56567877?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80"
//                 alt="gallery-photo"
//               />
//             </div>
//           </div>
//         </div>
//       </Container>
//     </section>
//   );
// };

// export default Gallery;
import Container from "../Common/Container";

const Gallery = () => {
  return (
    <section>
      <Container>
        <p className="text-primary font-semibold uppercase tracking-widest">
          Vuavot
        </p>
        <h3 className="font-semibold text-[30px]  mb-5 leading-none">
          Bộ sưu tập
        </h3>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 cursor-pointer">
          <div className="grid gap-4">
            <img
              className="w-full h-[550px] object-cover object-center rounded-lg  "
              alt=""
              src="https://plus.unsplash.com/premium_photo-1664304753883-923c28de6b85?q=80&w=1936&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <img
              className="w-full h-[300px] object-cover object-center rounded-lg  "
              alt=""
              src="https://plus.unsplash.com/premium_photo-1677543938005-6e0eb736dc19?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </div>
          <div className="grid gap-4">
            <img
              className="w-full h-[300px] object-cover object-center rounded-lg  "
              alt=""
              src="https://images.unsplash.com/photo-1708312604073-90639de903fc?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <img
              className="w-full h-[550px] object-cover object-center rounded-lg  "
              alt=""
              src="https://images.unsplash.com/photo-1599391398131-cd12dfc6c24e?q=80&w=1911&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </div>
          <div className="grid gap-4">
            <img
              className="w-full h-[550px] object-cover object-center rounded-lg  "
              alt=""
              src="https://images.unsplash.com/photo-1559309106-ed14040fd35d?q=80&w=1854&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <img
              className="w-full h-[300px] object-cover object-center rounded-lg  "
              alt=""
              src="https://plus.unsplash.com/premium_photo-1686910561611-05372ab095fc?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Gallery;
