import { useQuery } from "@tanstack/react-query";
import { FaArrowRightLong } from "react-icons/fa6";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchMainProducts } from "../../services/home";
import {
  formatTextFirstLetterUppercase,
  formatVietnamDong,
  sortedProductsHasImageComeFirst,
} from "../../shared/utils";
import Container from "../Common/Container";
import Title from "../Common/Title";
import ProductSlider from "../Common/ProductSlider";
const MainProductSecond = () => {
  const {
    isPending: isLoadingMainProduct,
    error: errorMainProduct,
    data: dataMainProduct,
  } = useQuery({
    queryKey: ["mainProducts"],
    queryFn: fetchMainProducts,
  });

  if (isLoadingMainProduct) {
    return <div>Loading...</div>;
  }

  if (errorMainProduct) {
    return <div>Error fetching main products</div>;
  }

  const accessory = dataMainProduct.data.find(
    (mainProduct) => mainProduct.categoryName === "Phụ Kiện Cầu Lông"
  );

  return (
    <section className="bg-lightWhite3">
      <Container>
        <Title title={accessory?.categoryName} />
        <ProductSlider products={accessory?.products} />
        {/* <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={4}
          navigation
          loop
        >
          {sortedProductsHasImageComeFirst(racquet?.products)?.map((p) => (
            <SwiperSlide className="pb-2">
              <div className="rounded-lg  bg-lightWhite shadow-md hover:scale-105 transition duration-300">
                <div className="h-72 flex justify-center items-center">
                  <img
                    src={
                      p.imageUrl ||
                      "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                    }
                    alt={p.productCode}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="px-3 pt-4 pb-3 bg-white">
                  <p className="font-medium text-black uppercase">
                    {p.productName}
                  </p>
                  <p className="italic font-light text-sm pt-1">
                    {formatTextFirstLetterUppercase(p.brand)}
                  </p>
                  <p className="mt-3 text-right ">
                    {formatVietnamDong(p.price)}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}
      </Container>
    </section>
  );
};

export default MainProductSecond;
