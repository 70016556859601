import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Slider = () => {
  return (
    <Swiper
      modules={[Autoplay]}
      // navigation
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      loop={true}
      className="w-full"
    >
      <SwiperSlide>
        <img
          src="https://cdn.shopvnb.com/img/1920x640/uploads/slider/1000z-launch-website-banner_1695177885.webp"
          alt="slider pic 1"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://cdn.shopvnb.com/img/1920x640/uploads/slider/banner-sale-12_1695182579.webp"
          alt="slider pic 2"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://cdn.shopvnb.com/img/1920x640/uploads/slider/astrox88-sd-key-visual-2880x1120-_1718650445.webp"
          alt="slider pic 3"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default Slider;
