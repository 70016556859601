// import { useState } from "react";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowRight,
//   MdOutlineCheckBox,
//   MdOutlineCheckBoxOutlineBlank,
// } from "react-icons/md";

// interface PriceRangeProps {
//   updateFilter: (newFilter: {
//     minPrice?: number | null;
//     maxPrice?: number | null;
//   }) => void;
// }

// const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const [selectedRange, setSelectedRange] = useState<number | null>(null);

//   const priceRanges = [
//     { label: "Dưới 500.000 đ", minPrice: 0, maxPrice: 500000 },
//     { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
//     { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
//     { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
//     { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
//   ];

//   const handlePriceChange = (
//     minPrice: number | null,
//     maxPrice: number | null,
//     index: number
//   ) => {
//     if (selectedRange === index) {
//       // Deselect if the same range is clicked
//       setSelectedRange(null);
//       updateFilter({ minPrice: null, maxPrice: null });
//     } else {
//       // Select a new range
//       setSelectedRange(index);
//       updateFilter({ minPrice, maxPrice });
//     }
//   };

//   return (
//     <div className="px-6 py-4 rounded-lg shadow-sm bg-lightWhite4">
//       <div className="flex justify-between items-center mb-2 ">
//         <h2 className="text-xl font-medium">Khoảng giá</h2>
//         <button onClick={() => setIsOpen((prev) => !prev)}>
//           {isOpen ? (
//             <MdKeyboardArrowDown size={25} />
//           ) : (
//             <MdKeyboardArrowRight size={25} />
//           )}
//         </button>
//       </div>
//       {isOpen && (
//         <ul className="flex flex-col text-[17px] gap-[6px]">
//           {priceRanges.map((range, index) => (
//             <button
//               key={index}
//               className={`flex items-center gap-1 ${
//                 selectedRange === index && "text-primary"
//               }`}
//               onClick={() =>
//                 handlePriceChange(range.minPrice, range.maxPrice, index)
//               }
//             >
//               {selectedRange === index ? (
//                 <MdOutlineCheckBox size={20} className="text-blue-600" /> // Active icon and color
//               ) : (
//                 <MdOutlineCheckBoxOutlineBlank size={20} />
//               )}
//               {range.label}
//             </button>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default FilterPrice;

import { useEffect, useState } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { useSearchParams } from "react-router-dom";

interface PriceRangeProps {
  updateFilter: (newFilter: {
    minPrice?: number | null;
    maxPrice?: number | null;
  }) => void;
}

const priceRanges = [
  { label: "Dưới 500.000 đ", minPrice: 0, maxPrice: 500000 },
  { label: "500.000 đ - 1 triệu", minPrice: 500000, maxPrice: 1000000 },
  { label: "1 triệu - 2 triệu", minPrice: 1000000, maxPrice: 2000000 },
  { label: "2 triệu - 3 triệu", minPrice: 2000000, maxPrice: 3000000 },
  { label: "Trên 3 triệu", minPrice: 3000000, maxPrice: null },
];

const FilterPrice: React.FC<PriceRangeProps> = ({ updateFilter }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchParams] = useSearchParams();
  const [selectedRange, setSelectedRange] = useState<number | null>(null);

  useEffect(() => {
    const minPrice = searchParams.get("minPrice")
      ? Number(searchParams.get("minPrice"))
      : null;
    const maxPrice = searchParams.get("maxPrice")
      ? Number(searchParams.get("maxPrice"))
      : null;

    const foundIndex = priceRanges.findIndex(
      (range) => range.minPrice === minPrice && range.maxPrice === maxPrice
    );

    setSelectedRange(foundIndex !== -1 ? foundIndex : null);
  }, [searchParams]);

  const handlePriceChange = (
    minPrice: number | null,
    maxPrice: number | null,
    index: number
  ) => {
    if (selectedRange === index) {
      // Deselect if the same range is clicked
      setSelectedRange(null);
      updateFilter({ minPrice: null, maxPrice: null });
    } else {
      // Select a new range
      setSelectedRange(index);
      updateFilter({ minPrice, maxPrice });
    }
  };

  return (
    <div className="px-6 py-4 rounded-lg shadow-sm bg-lightWhite4">
      <div className="flex justify-between items-center mb-2 ">
        <h2 className="text-xl font-medium">Khoảng giá</h2>
        <button onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? (
            <MdKeyboardArrowDown size={25} />
          ) : (
            <MdKeyboardArrowRight size={25} />
          )}
        </button>
      </div>
      {isOpen && (
        <ul className="flex flex-col text-[17px] gap-[6px]">
          {priceRanges.map((range, index) => (
            <button
              key={index}
              className={`flex items-center gap-1 ${
                selectedRange === index && "text-primary"
              }`}
              onClick={() =>
                handlePriceChange(range.minPrice, range.maxPrice, index)
              }
            >
              {selectedRange === index ? (
                <MdOutlineCheckBox size={20} className="text-blue-600" /> // Active icon and color
              ) : (
                <MdOutlineCheckBoxOutlineBlank size={20} />
              )}
              {range.label}
            </button>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterPrice;
