import BlobButton from "../Common/BlobButton";
import ButtonAnimation from "../Common/BlobButton";
import Container from "../Common/Container";
import "./ShineEffect.css";
const CallToAction = () => {
  return (
    <section>
      <Container>
        <div className="flex items-center gap-28">
          <div className="flex-1">
            <h2 className="text-[50px] font-bold text-center mb-6">
              Nhìn đã ấn tượng
            </h2>
            <p className="text-xl font-medium text-center">
              Tăng cường sức mạnh mỗi cú đánh với cây vợt cầu lông{" "}
              <span className="text-primary">siêu nhẹ và bền bỉ</span>. Thiết kế
              tối ưu giúp bạn kiểm soát hoàn hảo và linh hoạt trên sân. Đặt hàng
              ngay hôm nay để nhận{" "}
              <span className="italic text-primary font-semibold">
                {" "}
                ưu đãi 10%!{" "}
              </span>
            </p>
            <BlobButton />
          </div>
          <div className="shine-effect flex-1 overflow-x-hidden">
            <figure>
              <img
                className="w-full h-full object-cover object-center rounded-2xl"
                src="https://images.unsplash.com/photo-1708312604109-16c0be9326cd?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            </figure>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CallToAction;
