import { Product } from "../../shared/types";
import {
  formatTextFirstLetterUppercase,
  formatVietnamDong,
} from "../../shared/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface ProductItemProps {
  product: Product;
}

const ProductItem: React.FC<ProductItemProps> = ({ product }) => {
  return (
    <div className="rounded-lg  bg-blue-100 shadow-md hover:scale-105 hover:-translate-y-1 hover:brightness-105 transition duration-300 ">
      <div className="h-72 flex justify-center items-center">
        <LazyLoadImage
          src={
            product.imageUrl ||
            "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
          }
          alt={product.productCode}
          effect="opacity"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="px-3 pt-4 pb-3 bg-white">
        <p className="font-medium text-black uppercase">
          {product.productName}
        </p>
        <p className="italic font-light text-sm pt-1">
          {formatTextFirstLetterUppercase(product.brand)}
        </p>
        <p className="mt-3 text-right ">{formatVietnamDong(product.price)}</p>
      </div>
    </div>
  );
};

export default ProductItem;
