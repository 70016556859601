import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CollectionPage from "./pages/CollectionPage";
import ScrollToTop from "./components/Common/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route index path="/:category-slug" element={<CollectionPage />} />
      </Routes>
    </>
  );
}

export default App;
