export const CATEGORIES = [
  { CategoryCode: 102, CategoryName: "Dịch vụ", CategorySlug: "dich-vu" },
  {
    CategoryCode: 50,
    CategoryName: "Vợt cầu lông",
    CategorySlug: "vot-cau-long",
  },
  {
    CategoryCode: 57,
    CategoryName: "Quần Cầu Lông",
    CategorySlug: "quan-cau-long",
  },
  {
    CategoryCode: 56,
    CategoryName: "Váy Cầu Lông",
    CategorySlug: "vay-cau-long",
  },
  {
    CategoryCode: 58,
    CategoryName: "Balo Cầu Lông",
    CategorySlug: "balo-cau-long",
  },
  { CategoryCode: 43, CategoryName: "Heineken", CategorySlug: "heineken" },
  {
    CategoryCode: 51,
    CategoryName: "Túi Cầu Lông",
    CategorySlug: "tui-cau-long",
  },
  {
    CategoryCode: 59,
    CategoryName: "Phụ Kiện Cầu Lông",
    CategorySlug: "phu-kien-cau-long",
  },
  { CategoryCode: 44, CategoryName: "Tiger", CategorySlug: "tiger" },
  {
    CategoryCode: 52,
    CategoryName: "Giày Cầu Lông",
    CategorySlug: "giay-cau-long",
  },
  { CategoryCode: 47, CategoryName: "NVL TP Bia", CategorySlug: "nvl-tp-bia" },
  {
    CategoryCode: 55,
    CategoryName: "Áo Cầu Lông",
    CategorySlug: "ao-cau-long",
  },
];
