import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { fetchCategories } from "../../../services/home";
import { getCategorySlugFromName } from "../../../shared/utils";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

interface FilterCategoryProps {
  setFilters: any;
}

const FilterCategory: React.FC<FilterCategoryProps> = ({ setFilters }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { "category-slug": categorySlug } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    isPending: isLoadingCategory,
    error: errorCategory,
    data: dataCategory,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  if (isLoadingCategory) {
    return <div>Loading...</div>;
  }

  if (errorCategory) {
    return <div>Error fetching categories</div>;
  }

  const handleCategoryChange = (categoryName: string) => {
    const newCategorySlug = getCategorySlugFromName(categoryName);
    setFilters({ pageIndex: 1, pageSize: 12 });
    navigate(`/${newCategorySlug}`);
  };
  return (
    <div className="px-6 py-4 rounded-lg shadow-sm bg-lightWhite4">
      <div className="flex justify-between items-center mb-2 ">
        <h2 className="text-xl font-medium">Danh mục sản phẩm</h2>
        <button onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? (
            <MdKeyboardArrowDown size={25} />
          ) : (
            <MdKeyboardArrowRight size={25} />
          )}
        </button>
      </div>
      {isOpen && (
        <ul className="flex flex-col text-[17px] gap-[6px]">
          {dataCategory.data.map((c) => (
            <li key={c.categoryId}>
              <button
                className={`hover-brighter-color ${
                  categorySlug === getCategorySlugFromName(c.categoryName) &&
                  "font-medium"
                }`}
                onClick={() => handleCategoryChange(c.categoryName)}
              >
                {c.categoryName}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterCategory;
