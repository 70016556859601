import { Product } from "../../shared/types";
import ProductItem from "../Common/ProductItem";
import Skeleton from "../Common/Skeleton";

interface ProductCollectionProps {
  products?: Product[];
}

const ProductCollection: React.FC<ProductCollectionProps> = ({ products }) => {
  return (
    <ul className="grid grid-cols-lg gap-x-8 gap-y-10">
      {products &&
        products.map((product) => (
          <li key={product.productId}>
            <ProductItem product={product} />
          </li>
        ))}
      {!products &&
        [...new Array(20)].map((_, index) => (
          <li key={index}>
            <Skeleton className="h-0 pb-[120%]" />
          </li>
        ))}
    </ul>
  );
};

export default ProductCollection;
