import { FaTruck } from "react-icons/fa";
import Container from "../Common/Container";
import { RiExchangeFill } from "react-icons/ri";
import { AiFillLike } from "react-icons/ai";
import { FaRegCreditCard } from "react-icons/fa";
const Features = () => {
  return (
    <section>
      <Container className=" flex justify-between gap-16 items-start">
        <div className="flex flex-col items-center flex-1">
          <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
            <FaTruck className="text-primary" size={35} />
          </div>
          <h2 className="text-lg font-medium mt-4">
            Giao hàng nhanh, miễn phí
          </h2>
          <p className="text-gray-400 text-[13px] text-center">
            Đơn hàng hơn 900k hoặc đăng ký tài khoản được giao hàng miễn phí
          </p>
        </div>
        <div className="flex flex-col items-center flex-1">
          <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
            <RiExchangeFill className="text-primary" size={35} />
          </div>
          <h2 className="text-lg font-medium mt-4">Trả hàng, Bảo hành</h2>
          <p className="text-gray-400 text-[13px] text-center">
            Không thích? Trả lại hoặc đổi hàng của bạn miễn phí trong vòng 30
            ngày.
          </p>
        </div>
        <div className="flex flex-col items-center flex-1">
          <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
            <AiFillLike className="text-primary" size={35} />
          </div>
          <h2 className="text-lg font-medium mt-4">Thành viên</h2>
          <p className="text-gray-400 text-[13px] text-center">
            Ưu đã theo từng cấp bậc hạng thành viên. Sinh nhật quà tặng thành
            viên
          </p>
        </div>
        <div className="flex flex-col items-center flex-1">
          <div className="h-[75px] w-[75px] rounded-full bg-[#ededed] flex items-center justify-center">
            <FaRegCreditCard className="text-primary" size={35} />
          </div>
          <h2 className="text-lg font-medium mt-4">Chính hãng</h2>
          <p className="text-gray-400 text-[13px] text-center">
            Sản phẩm chính hãng. Được nhập khẩu 100% từ hãng.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Features;
