import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
interface BreadcrumbItem {
  label: string;
  link?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav className="flex mt-6 mb-7" aria-label="Breadcrumb">
      <ol className="inline-flex items-center font-light italic text-gray-600">
        {items.map((item, index) => (
          <li key={index} className="inline-flex items-center">
            {index !== items.length - 1 && item.link ? (
              <Link
                to={item.link}
                className="inline-flex items-center  hover-primary-color"
              >
                <div className="flex gap-1 items-center">
                  {/* {index === 0 ? <TiHome size={20} className="" /> : null} */}
                  {item.label}
                </div>
              </Link>
            ) : (
              <span className="ml-1 ">{item.label}</span>
            )}
            {index !== items.length - 1 && (
              <MdKeyboardArrowRight size={20} className="ml-2" />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
