import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  formatTextFirstLetterUppercase,
  formatVietnamDong,
  sortedProductsHasImageComeFirst,
} from "../../shared/utils";
import { Product } from "../../shared/types";
import ProductItem from "./ProductItem";

interface ProductSliderProps {
  products?: Product[];
}

const ProductSlider: React.FC<ProductSliderProps> = ({ products }) => {
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={30}
      slidesPerView={4}
      navigation
      loop
    >
      {sortedProductsHasImageComeFirst(products)?.map((p) => (
        <SwiperSlide key={p.productId} className="py-2">
          <ProductItem product={p} />
          {/* <div className="rounded-lg  bg-lightWhite shadow-md hover:scale-105 hover:-translate-y-1 hover:brightness-105 transition duration-300 ">
            <div className="h-72 flex justify-center items-center">
              <img
                src={
                  p.imageUrl ||
                  "https://www.bandg.com/assets/img/default-product-img.png?w=400&h=225&scale=both&mode=max"
                }
                alt={p.productCode}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="px-3 pt-4 pb-3 bg-white">
              <p className="font-medium text-black uppercase">
                {p.productName}
              </p>
              <p className="italic font-light text-sm pt-1">
                {formatTextFirstLetterUppercase(p.brand)}
              </p>
              <p className="mt-3 text-right ">{formatVietnamDong(p.price)}</p>
            </div>
          </div> */}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductSlider;
