import React from "react";
import "./BlobButton.css"; // For the SVG filter effects and custom animations
import { Link } from "react-router-dom";

const BlobButton = () => {
  return (
    <Link to="/collection" className="flex justify-center mt-12">
      <button className="relative px-10 py-4 text-lg font-bold text-primary uppercase transition-all duration-500 rounded-full bg-transparent border-2 border-primary outline-none cursor-pointer blob-btn hover:text-white ">
        Mua ngay
        <span className="absolute inset-0 z-[-1] overflow-hidden bg-white rounded-full blob-btn__inner">
          <span className="relative block w-full h-full blob-btn__blobs">
            <span className="absolute w-1/4 h-full bg-primary rounded-full blob-btn__blob"></span>
            <span className="absolute w-1/4 h-full bg-primary rounded-full blob-btn__blob"></span>
            <span className="absolute w-1/4 h-full bg-primary rounded-full blob-btn__blob"></span>
            <span className="absolute w-1/4 h-full bg-primary rounded-full blob-btn__blob"></span>
          </span>
        </span>
      </button>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="hidden">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              result="blur"
              stdDeviation="10"
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
              result="goo"
            ></feColorMatrix>
            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
          </filter>
        </defs>
      </svg>
    </Link>
  );
};

export default BlobButton;
