import { FaAngleDown } from "react-icons/fa";
import { fetchBrands, fetchCategories } from "../../services/home";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getCategorySlugFromName } from "../../shared/utils";

const Navigation = () => {
  const {
    isPending: isLoadingCategory,
    error: errorCategory,
    data: dataCategory,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const {
    isPending: isLoadingBrand,
    error: errorBrand,
    data: dataBrand,
  } = useQuery({
    queryKey: ["brands"],
    queryFn: fetchBrands,
  });

  if (isLoadingCategory) {
    return <div>Loading...</div>;
  }

  if (errorCategory) {
    return <div>Error fetching categories</div>;
  }

  if (isLoadingBrand) {
    return <div>Loading...</div>;
  }

  if (errorBrand) {
    return <div>Error fetching brands</div>;
  }

  return (
    <nav className="mt-4 text-sm">
      <ul className="flex justify-between  font-medium cursor-pointer">
        <li className="pb-3 uppercase hover-primary-color">
          <Link to="/">Trang chủ</Link>
        </li>
        <li className="pb-3 group hover-primary-color">
          <p className="flex gap-1 items-center uppercase">
            Sản phẩm
            <FaAngleDown size={17} />
          </p>
          <div className="absolute left-0 right-0 top-full bg-[#F7F7FF] text-gray-700 p-4  shadow-md opacity-0 invisible translate-y-4 group-hover:translate-y-0 group-hover:opacity-100 group-hover:visible transition duration-300 ease-out z-10 h-auto max-h-[420px] overflow-y-auto">
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-6 p-2`}
            >
              {dataCategory.data.map((parentCategory) => (
                <div key={parentCategory.categoryId} className="">
                  <Link
                    to={`/${getCategorySlugFromName(
                      parentCategory.categoryName
                    )}`}
                    className="text-base font-bold mb-2  text-primary"
                  >
                    {parentCategory.categoryName}
                  </Link>
                  <ul className="space-y-2 text-sm">
                    {parentCategory.childCategories.map((childCategory) => (
                      <li
                        key={childCategory.categoryId}
                        className="hover:text-primary transition duration-300"
                      >
                        {childCategory.categoryName}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </li>
        <li className="pb-3 group hover-primary-color">
          <p className="flex gap-1 items-center uppercase">
            Thương hiệu
            <FaAngleDown size={17} />
          </p>
          <div className="absolute left-0 right-0 top-full bg-[#F7F7FF] text-gray-700 p-4  shadow-md opacity-0 invisible translate-y-4 group-hover:translate-y-0 group-hover:opacity-100 group-hover:visible transition duration-300 ease-out z-10 h-auto max-h-[420px] overflow-y-auto">
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-x-6 gap-y-3 p-2`}
            >
              {dataBrand.data.map((parentBrand) => (
                <div key={parentBrand.brandId} className="">
                  <p className="text-sm mb-1 hover:text-primary transition duration-300">
                    {parentBrand.brandName}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </li>
        <li className="pb-3 uppercase hover-primary-color">Sale off</li>
        <li className="pb-3 uppercase hover-primary-color">Tin tức</li>
        <li className="pb-3 uppercase hover-primary-color">Chính sách</li>
        <li className="pb-3 uppercase hover-primary-color">Hướng dẫn</li>
        <li className="pb-3 uppercase hover-primary-color">Giới thiệu</li>
        <li className="pb-3 uppercase hover-primary-color">Liên hệ</li>
      </ul>
    </nav>
  );
};

export default Navigation;
