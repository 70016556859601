import { FC } from "react";
import Features from "../components/Home/Features";
import Slider from "../components/Home/Slider";
import Header from "../components/Layout/Header";
import MainProduct from "../components/Home/MainProduct";
import Gallery from "../components/Home/Gallery";
import MainProductSecond from "../components/Home/MainProductSecond";
import CallToAction from "../components/Home/CallToAction";

const HomePage: FC = () => {
  return (
    <div>
      <Header />
      <Slider />
      <Features />
      <MainProduct />
      <Gallery />
      <MainProductSecond />
      <CallToAction />
    </div>
  );
};

export default HomePage;
