import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import FilterCategory from "../components/Collection/FilterType/FilterCategory";
import FilterPrice from "../components/Collection/FilterType/FilterPrice";
import ProductCollection from "../components/Collection/ProductCollection";
import Breadcrumb from "../components/Common/Breadcrumb";
import Container from "../components/Common/Container";
import Header from "../components/Layout/Header";
import { fetchAllProducts } from "../services/home";
import { Filter } from "../shared/types";
import {
  getCategoryCodeFromSlug,
  getCategoryNameFromSlug,
  sortedProductsHasImageComeFirst,
} from "../shared/utils";

const CollectionPage = () => {
  const { "category-slug": categorySlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryCode = getCategoryCodeFromSlug(categorySlug);

  const [filters, setFilters] = useState<Filter>({
    minPrice: searchParams.get("minPrice")
      ? Number(searchParams.get("minPrice"))
      : null,
    maxPrice: searchParams.get("maxPrice")
      ? Number(searchParams.get("maxPrice"))
      : null,
    brands: searchParams.getAll("brands") || [], // Array of selected brand codes
    pageIndex: searchParams.get("pageIndex")
      ? Number(searchParams.get("pageIndex"))
      : 1,
    pageSize: searchParams.get("pageSize")
      ? Number(searchParams.get("pageSize"))
      : 12,
    orderBy: searchParams.get("orderBy") || null,
  });

  const breadcrumbItems = [
    { label: "Trang chủ", link: "/" },
    { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
  ];

  const { error: errorAllProduct, data: dataAllProduct } = useQuery({
    queryKey: [categoryCode, filters, "allProducts"],
    queryFn: () => fetchAllProducts(categoryCode, filters),
    placeholderData: keepPreviousData,
  });

  // Updating the filter state from child components
  const updateFilter = (newFilterValues: Partial<typeof filters>) => {
    const updatedFilters = {
      ...filters,
      ...newFilterValues,
    };
    setFilters(updatedFilters);

    const params = new URLSearchParams();
    if (updatedFilters.minPrice !== null)
      params.set("minPrice", updatedFilters.minPrice.toString());
    if (updatedFilters.maxPrice !== null)
      params.set("maxPrice", updatedFilters.maxPrice.toString());
    if (updatedFilters.brands?.length)
      updatedFilters.brands.forEach((brand) => params.append("brands", brand));
    if (updatedFilters.pageIndex !== 1)
      params.set("pageIndex", updatedFilters.pageIndex.toString());
    if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

    setSearchParams(params); // Update the URL
  };

  if (errorAllProduct) {
    return <div>Error fetching all products</div>;
  }

  return (
    <div>
      <Header />
      {/* <CategoryNavigation /> */}
      <Container>
        <Breadcrumb items={breadcrumbItems} />

        <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
          <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
            <FilterCategory setFilters={setFilters} />
            <FilterPrice updateFilter={updateFilter} />
          </aside>
          <div className="lg:w-3/4 w-full">
            {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
            <ProductCollection
              products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CollectionPage;

// import { keepPreviousData, useQuery } from "@tanstack/react-query";
// import { useEffect, useState } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import FilterCategory from "../components/Collection/FilterType/FilterCategory";
// import FilterPrice from "../components/Collection/FilterType/FilterPrice";
// import ProductCollection from "../components/Collection/ProductCollection";
// import Breadcrumb from "../components/Common/Breadcrumb";
// import Container from "../components/Common/Container";
// import Header from "../components/Layout/Header";
// import { fetchAllProducts } from "../services/home";
// import { Filter } from "../shared/types";
// import {
//   getCategoryCodeFromSlug,
//   getCategoryNameFromSlug,
//   sortedProductsHasImageComeFirst,
// } from "../shared/utils";

// const CollectionPage = () => {
//   const { "category-slug": categorySlug } = useParams();
//   const [searchParams, setSearchParams] = useSearchParams();
//   const categoryCode = getCategoryCodeFromSlug(categorySlug);

//   const [filters, setFilters] = useState<Filter>({} as Filter);

//   useEffect(() => {
//     const changeFilter = (key: string, value: string | number) => {
//       setFilters((prevConfig) => ({
//         ...prevConfig,
//         [key]: value,
//       }));
//     };

//     const sortType = searchParams.get("sort_by") || "popularity.desc";
//     changeFilter("sort_by", sortType);

//     const genreType = searchParams.getAll("genre") || [];
//     changeFilter("with_genres", genreType.toString());

//     const minRuntime = Number(searchParams.get("minRuntime")) || 0;
//     const maxRuntime = Number(searchParams.get("maxRuntime")) || 200;
//     changeFilter("with_runtime.gte", minRuntime);
//     changeFilter("with_runtime.lte", maxRuntime);

//     const releaseFrom = searchParams.get("from") || "2002-11-04";
//     const releaseTo = searchParams.get("to") || "2022-07-28";
//     changeFilter("primary_release_date.gte", releaseFrom);
//     changeFilter("primary_release_date.lte", releaseTo);
//     changeFilter("air_date.gte", releaseFrom);
//     changeFilter("air_date.lte", releaseTo);

//     // eslint-disable-next-line
//   }, [window.location.search]);

//   const breadcrumbItems = [
//     { label: "Trang chủ", link: "/" },
//     { label: getCategoryNameFromSlug(categorySlug) || "", link: categorySlug },
//   ];

//   const { error: errorAllProduct, data: dataAllProduct } = useQuery({
//     queryKey: [categoryCode, filters, "allProducts"],
//     queryFn: () => fetchAllProducts(categoryCode, filters),
//     placeholderData: keepPreviousData,
//   });

//   // Updating the filter state from child components
//   const updateFilter = (newFilterValues: Partial<typeof filters>) => {
//     const updatedFilters = {
//       ...filters,
//       ...newFilterValues,
//     };
//     setFilters(updatedFilters);

//     const params = new URLSearchParams();
//     if (updatedFilters.minPrice !== null)
//       params.set("minPrice", updatedFilters.minPrice.toString());
//     if (updatedFilters.maxPrice !== null)
//       params.set("maxPrice", updatedFilters.maxPrice.toString());
//     if (updatedFilters.brands.length)
//       updatedFilters.brands.forEach((brand) => params.append("brands", brand));
//     if (updatedFilters.pageIndex !== 1)
//       params.set("pageIndex", updatedFilters.pageIndex.toString());
//     if (updatedFilters.orderBy) params.set("orderBy", updatedFilters.orderBy);

//     setSearchParams(params); // Update the URL
//   };

//   if (errorAllProduct) {
//     return <div>Error fetching all products</div>;
//   }

//   return (
//     <div>
//       <Header />
//       {/* <CategoryNavigation /> */}
//       <Container>
//         <Breadcrumb items={breadcrumbItems} />

//         <div className="flex flex-col lg:flex-row lg:gap-6 min-h-screen">
//           <aside className="lg:w-1/4 lg:mb-0 w-full mb-6 flex flex-col gap-6">
//             <FilterCategory />
//             <FilterPrice updateFilter={updateFilter} />
//           </aside>
//           <div className="lg:w-3/4 w-full">
//             {dataAllProduct?.data.length === 0 && <p>Không có sản phẩm</p>}
//             <ProductCollection
//               products={sortedProductsHasImageComeFirst(dataAllProduct?.data)}
//             />
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default CollectionPage;
