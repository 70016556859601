import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getCategorySlugFromName } from "../../shared/utils";

interface TitleProps {
  title?: string;
}

const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <div className="flex justify-between items-end mb-5">
      <div>
        <p className="text-primary font-semibold uppercase tracking-widest">
          Vuavot
        </p>
        <h3 className="font-semibold text-[30px]  leading-none">{title}</h3>
      </div>
      <button className="flex items-center gap-1 hover-primary-color">
        <Link
          to={`/${getCategorySlugFromName(title)}`}
          className="uppercase tracking-widest font-medium"
        >
          Xem thêm
        </Link>
        <FaArrowRightLong />
      </button>
    </div>
  );
};

export default Title;
