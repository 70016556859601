import { FiHeart, FiShoppingCart, FiUser } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import logo from "../../assets/logo.png";
import Container from "../Common/Container";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <header className="pt-3 shadow-md relative">
      <Container>
        <div className="flex items-center justify-between">
          <Link to="/" className="flex gap-1 items-center">
            <img src={logo} alt="logo" className="w-14" />
            <h1 className="font-bold text-2xl -rotate-2">
              <span className="text-primary"> Vua </span>Vợt
            </h1>
          </Link>
          <div className="relative w-full max-w-xl">
            <input
              type="text"
              className="bg-gray-200 py-2 pl-3 pr-10 text-[13px] w-full rounded-md "
              placeholder="Bạn cần tìm gì...?"
            />
            <button className="absolute right-2 top-1/2 -translate-y-1/2 hover-primary-color">
              <IoSearch size={20} />
            </button>
          </div>

          <div className="flex gap-5 text-sm">
            <button className="flex gap-2 items-center hover-primary-color">
              <span>Tài khoản</span>
              <FiUser size={20} />
            </button>
            <button className="flex gap-2 items-center hover-primary-color">
              <span>Yêu thích</span>
              <FiHeart size={20} />
            </button>
            <button className="flex gap-2 items-center hover-primary-color">
              <span>Giỏ hàng</span>
              <FiShoppingCart size={20} />
            </button>
          </div>
        </div>
        <Navigation />
      </Container>
    </header>
  );
};

export default Header;
